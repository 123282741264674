import 'animate.css';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { characters } from '../../constants';
import { resetReducer, setActiveCharIndexReducer, setActiveCharReducer, setCharacterPartsReducer, setShuffleIndexReducer, setUnOrderedPartsReducer } from '../../slices/character';
import { randomNumber } from '../../utilities';
import { Canvas } from './Canvas';
import ColorSwitcher from './ColorSwitcher';
import Footer from './Footer';
import Header from './Header';

const bc = new BroadcastChannel("canvas");

export default function Index(){
    const {characterParts, unOrderedParts, activeCharIndex, activeChar, colors, shuffleIndex} = useSelector(state => state.character);
    const mounted = useRef(false);
    const params = useParams();
    const [comingSoon, setComingSoon] = useState(false);
    const [activeIndex, setActiveIndex] = useState(params?.charSet ?? 1);
    const [undoList, setUndoList] = useState([]);
    const [redoList, setRedoList] = useState([]);
    const [currentSelection, setCurrentSelection] = useState(null);
    const dispatch = useDispatch();

    const setCharacterParts = payload => dispatch(setCharacterPartsReducer(payload));
    const setUnOrderedParts = payload => dispatch(setUnOrderedPartsReducer(payload));

    const updatePart = (e, index, part) => {
        e.preventDefault();
        const parts = [...characterParts];
        setUndoList([...undoList, {key: 'characterParts', previousState: [...characterParts]}]);
        parts[index] = part;
        setCharacterParts(parts);
        setCurrentSelection(part);
    }

    const updateUnorderPart = (e, index, part, connector) => {
        e.preventDefault();
        const parts = {...unOrderedParts};
        setUndoList([...undoList, {key: 'unOrderedParts', previousState: {...unOrderedParts}}]);
        setUnOrderedParts({ ...parts, [index]: { ...(parts?.[index] ?? {}), [connector.category]: { part, connector } } });
        setCurrentSelection(part);
    }

    const arrangeCharParts = (activeChar, shuffle = false) => {
        let unOrdersParts = {};
        let activeParts = [];
        _.sortBy(activeChar, ["index"]).map(ac => {
            let acActiveIndex = shuffle && ac?.variations?.length ? randomNumber(ac.variations.length - 1) : ac?.activeVariationIndex;
            acActiveIndex >= 0 && activeParts.push(ac.variations[acActiveIndex])
            ac?.connectors?.map(connector => {
                let connectorActiveIndex = shuffle && connector?.variations?.length ? randomNumber(connector.variations.length - 1) : connector?.activeVariationIndex;
                if(connectorActiveIndex >= 0){
                    unOrdersParts = {...unOrdersParts, [ac.index]: { ...(unOrdersParts?.[ac.index] ?? {}), [connector.category]: { part: connector.variations[connectorActiveIndex], connector } }}
                }
            });
        });

        setCharacterParts(activeParts);
        setUnOrderedParts(unOrdersParts);
    }

    useEffect(() => {
        dispatch(setShuffleIndexReducer(null));
        setTimeout(() => mounted.current = true, 1)
    }, [])

    useEffect(() => {
        if(activeIndex != activeCharIndex){
            const activeChar = characters?.[activeIndex]?.composition ?? null;
            if(!activeChar){
                setComingSoon(true);
                return;
            }
            dispatch(resetReducer());
            dispatch(setActiveCharReducer(activeChar));
            dispatch(setActiveCharIndexReducer(activeIndex));
            arrangeCharParts(activeChar);
        }
    }, [activeCharIndex, activeIndex])

    // Character Shuffle
    useEffect(() => {
        if(!shuffleIndex || !mounted.current)
            return;

        const activeChar = characters[activeIndex].composition;
        arrangeCharParts(activeChar, true);
    }, [shuffleIndex])

    // Auto reload preview if there is any change in character
    useEffect(() => {
        bc.postMessage({characterParts, unOrderedParts, activeCharIndex, activeChar, colors});
    }, [characterParts, unOrderedParts, activeCharIndex, activeChar, colors])

    const undoAction = (e) => {
        e.preventDefault(); 

        if(undoList.length){
            const actionIndex = undoList.length - 1;
            const action = undoList[actionIndex];
            setRedoList([...redoList, {...action}]);
            undoList.splice(actionIndex, 1);

            if(action.key === 'characterParts'){
                setCharacterParts(action.previousState);
            }else{
                if(action.key === 'unOrderedParts'){
                setUnOrderedParts(action.previousState);
                }
            }
        }
    }

    const redoAction = (e) => {
        e.preventDefault();

        if(redoList.length){
            const actionIndex = redoList.length - 1;
            const action = redoList[actionIndex];
            setUndoList([...undoList, {...action}]);
            redoList.splice(actionIndex, 1);

            if(action.key === 'characterParts'){
                setCharacterParts(action.previousState);
            }else{
                if(action.key === 'unOrderedParts'){
                setUnOrderedParts(action.previousState);
                }
            }
        }
    }
    return (
        <div className="main-Wrap">
            <Header comingSoon={comingSoon} />
            <section className="main-sec">
                {
                    comingSoon === false
                    ?
                    <div className="main-sec-blk Oodles-character">
                        <div className="right-display-sidebar">
                            <div className="tools-section">
                                <ColorSwitcher />
                            </div>
                            <div className="main-img">
                                <div className="create-character">
                                    <div className="character-block">
                                    <Canvas currentSelection={currentSelection} updatePart={updatePart} updateUnorderPart={updateUnorderPart} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{textAlign: 'center', paddingTop: '20%'}}>
                        <h1>Coming Soon ...</h1>
                    </div>
                }
                
            </section>
            {comingSoon === false && <Footer undoAction={undoAction} redoAction={redoAction} undoList={undoList} redoList={redoList} />}
        </div>
    )
}
