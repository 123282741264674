import React from 'react'
import ExportDropDown from './ExportDropDown'

 export default function Header({comingSoon = false}){
  return (
    <header className="header nw-header">
        <div className="container">
            <nav className="navbar navbar-expand-sm">
            {/* Brand */}
            <div className="navbar-left">
                <ul className="navbar-menu">
                <li className="navbar-menu-li"><a className="navbar-brand" href="index.html"><img src="/tool/images/oodles-new-logo.svg" className="site-logo" /></a></li>
                <li className="navbar-menu-li"><a className="navbar-brand" href="#">Explore Library</a></li>
                </ul>
            </div>
            <div className="header-btn gap-12">
                <div className="header-right-section">
                <div className="add-folder model-toggle" id="add-folder">
                    <button className="btn-folder cmn-btn">
                    <span className="bt-icon"><img src="/tool/images/Folder.svg" /></span>
                    </button>
                </div>
                {
                    comingSoon === false
                    &&
                    <>
                        <ExportDropDown />
                        <div className="btn-group for-download model-toggle share-full-preview" dropdown style={{display: 'none'}}>
                            <button className="btn-download share-preview">
                            <img src="/tool/images/share-ic.svg" /> <span className="sl-title">Share Preview</span>
                            </button>
                            <div className="model-sets" style={{display: 'none'}}>
                            <div className="ul-model">
                                <div className="share-links">
                                <ul className="sh-links">
                                    <li className="links-li"><a href="#" className="links-href pinterest"><img src="/tool/images/white-print.svg" /> Pinterest</a></li>
                                    <li className="links-li"><a href="#" className="links-href facebook"><img src="/tool/images/white-facebook.svg" /> Facebook</a></li>
                                    <li className="links-li"><a href="#" className="links-href instagram"><img src="/tool/images/white-inasta.svg" /> Instagram</a></li>
                                    <li className="links-li"><a href="#" className="links-href twitter"><img src="/tool/images/white-twt.svg" /> Twitter</a></li>
                                </ul>
                                <div className="link-url">
                                    <div className="copy-url"><span className="share-ico"><img src="/tool/images/share-ico.svg" /></span><span className="url-content">oddles.com/untitled/sharelinked</span></div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <a className="btn-preview" href="/preview" target="_blank">
                            <span className="preview-play">
                                <img src="/tool/images/Arrow-play.svg" />
                            </span>
                        </a>
                    </>
                }
                
                <div className="profile model-toggle" style={{position: 'relative'}}>
                    <div className="user-profile">
                    <figure className="profile-figure">
                        <img src="/tool/images/dumy-prfl.svg" />
                    </figure>
                    </div>
                    <div className="user-deta" style={{display: 'none'}}>
                    <ul className="user-data-list">
                        <li className="user-list"><a href="javascript:void();" className="list-anchor"><i className="list-ico"><img src="/tool/images/account-profile.svg" /></i> Account</a></li>
                        <li className="user-list"><a href="javascript:void();" className="list-anchor"><i className="list-ico"><img src="/tool/images/collection.svg" /></i> My collection</a></li>
                        <li className="user-list "><a href="javascript:void();" className="list-anchor account"> Log out</a></li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </nav>
        </div>
    </header>
  )
}
