import React from 'react'
import { useDispatch } from 'react-redux'
import { setShuffleIndexReducer } from '../../slices/character'

const Footer = (props) => {

  const dispatch = useDispatch();

  return (
    <footer className="footer">
        <div className="container">
            <div className="right-side-bottom">
            <div className="rs-btm-col rs-left">
                <div className="info-button">
                <button className="get-info"><img src="/tool/images/get-info.svg" /></button>
                <span className="tool-tip">Need help?</span>
                </div>
            </div>
            <div className="rs-btm-col rs-middle">
                <div className="rs-top-btns">
                <ul className="rs-btn-list">
                    <li className="rs-btn-item">
                        <a href="#" className={`top-btnw undo-btn ${props.undoList.length ? '' : 'disabled'}`} onClick={props.undoAction}>
                            {
                                props.undoList.length
                                ?
                                <img src="/tool/images/undo-btn.svg" />
                                :
                                <img src="/tool/images/redo-btn.svg" style={{transform: 'rotateY(180deg'}} />
                            }
                          
                        </a>
                    </li>
                    <li className="rs-btn-item">
                      <a href="#" className={`top-btnw redo-btn ${props.redoList.length ? '' : 'disabled'}`} onClick={props.redoAction}>
                            {
                                props.redoList.length
                                ?
                                <img src="/tool/images/undo-btn.svg" style={{transform: 'rotateY(180deg'}} />
                                :
                                <img src="/tool/images/redo-btn.svg" />
                            }
                          
                        </a>
                    </li>
                </ul>
                <span className="part-st" />
                <div className="zoomIn">
                    <ul className="rs-btn-list zoom-size">
                    <li className="rs-btn-item">
                        <a href="#" className="top-btnw undo-btn"><img src="/tool/images/minus-btn.svg" /></a>
                    </li>
                    <li className="rs-btn-item">
                        <span className="zoom-ratio">100%</span>
                    </li>
                    <li className="rs-btn-item">
                        <a href="#" className="top-btnw redo-btn"><img src="/tool/images/add-btn.svg" /></a>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            <div className="rs-btm-col rs-right">
                <div className="btn-shuffle">
                <button className="shuffle-button" onClick={e => dispatch(setShuffleIndexReducer(Date.now()))}>
                    <img src="/tool/images/shuffle.svg" />
                </button>
                </div>
            </div>  
            </div>
        </div>
    </footer>
  )
}

export default Footer