export const characters = [
    {
      name: 'Cute Girl',
      thumbnail: '/images/charcter1.svg',
      composition: [
        {
          category: 'Hair Top',
          index: 1,
          thumbnail: '/images/hair-top.svg',
          thumbnailActive: '/images/hair-top-active.svg',
          variations: ['/cute_girl/hairtop/1', '/cute_girl/hairtop/2', '/cute_girl/hairtop/3', '/cute_girl/hairtop/4', '/cute_girl/hairtop/5', '/cute_girl/hairtop/6', '/cute_girl/hairtop/7'],
          activeVariationIndex: 0,
          zIndex: 1,
          connectors: [
            {
              category: 'Head Gear',
              thumbnail: '/images/headgear.svg',
              thumbnailActive: '/images/headgear-active.svg',
              variations: ['/cute_girl/headgear/1', '/cute_girl/headgear/2', '/cute_girl/headgear/3', '/cute_girl/headgear/4', '/cute_girl/headgear/5', '/cute_girl/headgear/6', '/cute_girl/headgear/7', '/cute_girl/headgear/8', '/cute_girl/headgear/9', '/cute_girl/headgear/10', '/cute_girl/headgear/11', '/cute_girl/headgear/12'],
              connectingXProp: 'xg',
              connectingYProp: 'yg',
              verticle: true,
              zIndex: 3
            },
            {
              category: 'Hair Back',
              thumbnail: '/images/hair-back.svg',
              thumbnailActive: '/images/hair-back-active.svg',
              variations: ['/cute_girl/hairback/1', '/cute_girl/hairback/2', '/cute_girl/hairback/3', '/cute_girl/hairback/4', '/cute_girl/hairback/5', '/cute_girl/hairback/6'],
              connectingXProp: 'xh',
              connectingYProp: 'yh',
              zIndex: 2
            },
            {
              category: 'Glasses',
              thumbnail: '/images/glasses.svg',
              thumbnailActive: '/images/glasses-active.svg',
              variations: ['/cute_girl/glasses/1', '/cute_girl/glasses/2', '/cute_girl/glasses/3', '/cute_girl/glasses/4', '/cute_girl/glasses/5', '/cute_girl/glasses/6', '/cute_girl/glasses/7', '/cute_girl/glasses/8', '/cute_girl/glasses/9', '/cute_girl/glasses/10', '/cute_girl/glasses/11', '/cute_girl/glasses/12'],
              connectingXProp: 'xl',
              connectingYProp: 'yl',
              zIndex: 3
            },
            {
              category: 'Expressions',
              thumbnail: '/images/expressions.svg',
              thumbnailActive: '/images/expressions-active.svg',
              variations: ['/cute_girl/expressions/1', '/cute_girl/expressions/2', '/cute_girl/expressions/3', '/cute_girl/expressions/4', '/cute_girl/expressions/5', '/cute_girl/expressions/6', '/cute_girl/expressions/7', '/cute_girl/expressions/8', '/cute_girl/expressions/9', '/cute_girl/expressions/10'],
              connectingXProp: 'xe',
              connectingYProp: 'ye',
              zIndex: 2
            }
          ]
        },
        {
          category: 'Body',
          index: 0,
          thumbnail: '/images/body.svg',
          thumbnailActive: '/images/body-active.svg',
          variations: ['/cute_girl/body/1', '/cute_girl/body/2', '/cute_girl/body/3', '/cute_girl/body/4', '/cute_girl/body/5', '/cute_girl/body/6', '/cute_girl/body/7', '/cute_girl/body/8'],
          activeVariationIndex: 0,
          zIndex: 1,
          connectors: [
            {
              category: 'Props',
              thumbnail: '/images/props.svg',
              thumbnailActive: '/images/props-active.svg',
              variations: ['/cute_girl/props/1', '/cute_girl/props/2', '/cute_girl/props/3', '/cute_girl/props/4'],
              connectingXProp: 'xl',
              connectingYProp: 'yl',
              zIndex: 4
            }
          ]
        }
      ]
    },
    {
      name: 'Cool Boy',
      thumbnail: '/images/charcter2.svg',
      composition: [
        {
          category: 'Expressions',
          index: 2,
          thumbnail: '/boy/expressions/1.svg',
          thumbnailActive: '/boy/expressions/1.svg',
          variations: ['/boy/expressions/1', '/boy/expressions/2', '/boy/expressions/3', '/boy/expressions/4', '/boy/expressions/5', '/boy/expressions/6', '/boy/expressions/7'],
          color: [['skin', "Skin"]],
          activeVariationIndex: 0,
          zIndex: 1,
          connectors: [
            {
              category: 'Hat',
              thumbnail: '/boy/hat/1.svg',
              thumbnailActive: '/boy/hat/1.svg',
              variations: ['/boy/hat/1', '/boy/hat/2', '/boy/hat/1', '/boy/hat/2', '/boy/hat/1', '/boy/hat/2', '/boy/hat/1', '/boy/hat/2'],
              connectingXProp: 'xh',
              connectingYProp: 'yh',
              verticle: true,
              activeVariationIndex: 0,
              zIndex: 3
            },
            {
              category: 'Hair',
              thumbnail: '/boy/hair/1.svg',
              thumbnailActive: '/boy/hair/1.svg',
              variations: ['/boy/hair/1', '/boy/hair/2', '/boy/hair/3', '/boy/hair/4', '/boy/hair/5', '/boy/hair/6'],
              color: [['hair', "Hair"]],
              connectingXProp: 'xhr',
              connectingYProp: 'yhr',
              verticle: true,
              activeVariationIndex: 0,
              zIndex: 2
            }
          ]
        },
        {
          category: 'Upper Body',
          index: 1,
          thumbnail: '/boy/upper_body/1.svg',
          thumbnailActive: '/boy/upper_body/1.svg',
          variations: ['/boy/upper_body/1', '/boy/upper_body/2', '/boy/upper_body/3', '/boy/upper_body/4', '/boy/upper_body/5'],
          color: [['shirt', "Shirt"], ['book', 'Book'], ['bag', 'Bag']],
          activeVariationIndex: 0,
          zIndex: 4,
          connectors: [
            {
              category: 'Umbrella',
              thumbnail: '/boy/umbrella/1.svg',
              thumbnailActive: '/boy/umbrella/1.svg',
              variations: ['/boy/umbrella/1', '/boy/umbrella/2', '/boy/umbrella/3', '/boy/umbrella/4'],
              connectingXProp: 'xu',
              connectingYProp: 'yu',
              zIndex: 5
            },
            {
              category: 'Accessories',
              thumbnail: '/boy/accessories/1.svg',
              thumbnailActive: '/boy/accessories/1.svg',
              variations: ['/boy/accessories/1', '/boy/accessories/2'],
              connectingXProp: 'xa',
              connectingYProp: 'ya',
              verticle: true,
              zIndex: 5
            }
          ]
        },
        {
          category: 'Lower Body',
          index: 0,
          thumbnail: '/boy/lower_body/1.svg',
          thumbnailActive: '/boy/lower_body/1.svg',
          variations: ['/boy/lower_body/1', '/boy/lower_body/2', '/boy/lower_body/3', '/boy/lower_body/4', '/boy/lower_body/5'],
          color: [['skin', "Skin"], ['pants', "Pants"], ['shoes', 'Shoes']],
          activeVariationIndex: 0,
          zIndex: 2
        }
      ]
    },
    {
      name: 'Office Scene (Male)',
      thumbnail: '/images/charcter2.svg',
      composition: [
        {
          category: 'Head',
          index: 1,
          thumbnail: '/office_male/head/1.svg',
          thumbnailActive: '/office_male/head/1.svg',
          variations: ['/office_male/head/1', '/office_male/head/2', '/office_male/head/3', '/office_male/head/4', '/office_male/head/5'],
          color: [['skin', "Skin"], ['hair', 'Hair'], ['beard', 'Beard']],
          activeVariationIndex: 0,
          zIndex: 4,
          connectors: [
            {
              category: 'Expressions',
              thumbnail: '/office_male/expressions/1.svg',
              thumbnailActive: '/office_male/expressions/1.svg',
              variations: ['/office_male/expressions/1', '/office_male/expressions/2', '/office_male/expressions/3', '/office_male/expressions/4', '/office_male/expressions/5'],
              connectingXProp: 'xe',
              connectingYProp: 'ye',
              activeVariationIndex: 0,
              zIndex: 5
            }
          ]
        },
        {
          category: 'Body',
          index: 0,
          thumbnail: '/office_male/body/1.svg',
          thumbnailActive: '/office_male/body/1.svg',
          variations: ['/office_male/body/1', '/office_male/body/2', '/office_male/body/3', '/office_male/body/4'],
          activeVariationIndex: 0,
          color: [['upperwear', "Upper Wear"], ['bottomwear', "Bottom Wear"], ['tie', "Tie"], ['shirt', "Shirt"], ['shoes', 'Shoes']],
          zIndex: 4,
          connectors: [
            {
              category: 'Background',
              thumbnail: '/office_male/backgrounds/1.svg',
              thumbnailActive: '/office_male/backgrounds/1.svg',
              variations: ['/office_male/backgrounds/1', '/office_male/backgrounds/2', '/office_male/backgrounds/3', '/office_male/backgrounds/4'],
              activeVariationIndex: 1,
              connectingXProp: 'xbc',
              connectingYProp: 'ybc',
              horizontal: true,
              verticle: true,
              zIndex: 2
            },
            {
              category: 'Chair',
              thumbnail: '/office_male/chairs/1.svg',
              thumbnailActive: '/office_male/chairs/1.svg',
              variations: ['/office_male/chairs/1', '/office_male/chairs/2', '/office_male/chairs/3', '/office_male/chairs/4'],
              color: [['chair', "Chair"]],
              activeVariationIndex: 0,
              connectingXProp: 'xc',
              connectingYProp: 'yc',
              horizontal: true,
              zIndex: 3
            },
            {
              category: 'Table',
              thumbnail: '/office_male/table/1.svg',
              thumbnailActive: '/office_male/table/1.svg',
              variations: ['/office_male/table/1', '/office_male/table/2', '/office_male/table/3', '/office_male/table/4'],
              color: [['table', "Table"]],
              activeVariationIndex: 0,
              connectingXProp: 'xtb',
              connectingYProp: 'ytb',
              horizontal: true,
              zIndex: 5
            },
            {
              category: 'TableTop',
              thumbnail: '/office_male/tabletop/1.svg',
              thumbnailActive: '/office_male/tabletop/1.svg',
              variations: ['/office_male/tabletop/1', '/office_male/tabletop/2', '/office_male/tabletop/3', '/office_male/tabletop/4', '/office_male/tabletop/5', '/office_male/tabletop/6', '/office_male/tabletop/7'],
              activeVariationIndex: 0,
              color: [['laptop', "Laptop"]],
              connectingXProp: 'xtt',
              connectingYProp: 'ytt',
              zIndex: 5
            }
          ]
        }
      ]
    },
    {
      name: 'Office Scene (Female)',
      thumbnail: '/images/charcter2.svg',
      composition: [
        {
          category: 'Head',
          index: 1,
          thumbnail: '/office_female/head/1.svg',
          thumbnailActive: '/office_female/head/1.svg',
          variations: ['/office_female/head/1', '/office_female/head/2', '/office_female/head/3', '/office_female/head/4'],
          color: [['skin', "Skin"], ['hair', 'Hair']],
          activeVariationIndex: 0,
          zIndex: 3,
          connectors: [
            {
              category: 'Expressions',
              thumbnail: '/office_female/expressions/1.svg',
              thumbnailActive: '/office_female/expressions/1.svg',
              variations: ['/office_female/expressions/1', '/office_female/expressions/2', '/office_female/expressions/3', '/office_female/expressions/4'],
              connectingXProp: 'xe',
              connectingYProp: 'ye',
              activeVariationIndex: 0,
              zIndex: 5
            }
          ]
        },
        {
          category: 'Body',
          index: 0,
          thumbnail: '/office_female/body/1.svg',
          thumbnailActive: '/office_female/body/1.svg',
          variations: ['/office_female/body/1', '/office_female/body/2', '/office_female/body/3', '/office_female/body/4'],
          activeVariationIndex: 0,
          color: [['upperwear', "Upper Wear"], ['bottomwear', "Bottom Wear"], ['outfit', "Outfit"], ['tshirt', "T-Shirt"], ['shoes', 'Shoes']],
          zIndex: 4,
          connectors: [
            {
              category: 'Background',
              thumbnail: '/office_female/backgrounds/1.svg',
              thumbnailActive: '/office_female/backgrounds/1.svg',
              variations: ['/office_female/backgrounds/1', '/office_female/backgrounds/2', '/office_female/backgrounds/3', '/office_female/backgrounds/4'],
              activeVariationIndex: 1,
              connectingXProp: 'xbc',
              connectingYProp: 'ybc',
              horizontal: true,
              verticle: true,
              zIndex: 1
            },
            {
              category: 'Chair',
              thumbnail: '/office_female/chairs/1.svg',
              thumbnailActive: '/office_female/chairs/1.svg',
              variations: ['/office_female/chairs/1', '/office_female/chairs/2', '/office_female/chairs/3', '/office_female/chairs/4'],
              color: [['chair', "Chair"]],
              activeVariationIndex: 0,
              connectingXProp: 'xc',
              connectingYProp: 'yc',
              horizontal: true,
              zIndex: 2
            },
            {
              category: 'Table',
              thumbnail: '/office_female/table/1.svg',
              thumbnailActive: '/office_female/table/1.svg',
              variations: ['/office_female/table/1', '/office_female/table/2', '/office_female/table/3', '/office_female/table/4'],
              color: [['table', "Table"]],
              activeVariationIndex: 0,
              connectingXProp: 'xtb',
              connectingYProp: 'ytb',
              horizontal: true,
              zIndex: 5
            },
            {
              category: 'TableTop',
              thumbnail: '/office_female/tabletop/1.svg',
              thumbnailActive: '/office_female/tabletop/1.svg',
              variations: ['/office_female/tabletop/1', '/office_female/tabletop/2', '/office_female/tabletop/3', '/office_female/tabletop/4', '/office_female/tabletop/5', '/office_female/tabletop/6', '/office_female/tabletop/7'],
              activeVariationIndex: 0,
              color: [['laptop', "Laptop"]],
              connectingXProp: 'xtt',
              connectingYProp: 'ytt',
              zIndex: 5
            }
          ]
        }
      ]
    },
    {
      name: 'Delivery',
      thumbnail: '/images/charcter2.svg',
      composition: [
        {
          category: 'Head',
          index: 2,
          thumbnail: '/delivery/head/1.svg',
          thumbnailActive: '/delivery/head/1.svg',
          variations: ['/delivery/head/1', '/delivery/head/2', '/delivery/head/3', '/delivery/head/4','/delivery/head/5', '/delivery/head/6', '/delivery/head/7', '/delivery/head/8', '/delivery/head/9', '/delivery/head/10', '/delivery/head/11'],
          color: [['skin', "Skin"], ['hair', 'Hair'], ['helmet', 'Helmet'], ['cap', 'Cap']],
          activeVariationIndex: 0,
          zIndex: 4,
          connectors: [
            {
              category: 'Expressions',
              thumbnail: '/delivery/expressions/1.svg',
              thumbnailActive: '/delivery/expressions/1.svg',
              variations: ['/delivery/expressions/1', '/delivery/expressions/2', '/delivery/expressions/3', '/delivery/expressions/4', '/delivery/expressions/5', '/delivery/expressions/6', '/delivery/expressions/7'],
              connectingXProp: 'xe',
              connectingYProp: 'ye',
              activeVariationIndex: 0,
              zIndex: 5
            }
          ]
        },
        {
          category: 'Body',
          index: 1,
          thumbnail: '/delivery/body/1.svg',
          thumbnailActive: '/delivery/body/1.svg',
          variations: ['/delivery/body/1', '/delivery/body/2', '/delivery/body/3', '/delivery/body/4', '/delivery/body/5'],
          activeVariationIndex: 0,
          color: [['upperwear', "Upper Wear"], ['blazer', "Blazer"], ['shirt', "Shirt"], ['bag', "Backpack"], ["skin", "Skin"]],
          zIndex: 4
        },
        {
          category: 'Vehicle',
          index: 0,
          thumbnail: '/delivery/vehicle/1.svg',
          thumbnailActive: '/delivery/vehicle/1.svg',
          variations: ['/delivery/vehicle/1', '/delivery/vehicle/2', '/delivery/vehicle/3', '/delivery/vehicle/4', '/delivery/vehicle/5', '/delivery/vehicle/6', '/delivery/vehicle/7'],
          color: [['bottomwear', "Bottom Wear"]],
          activeVariationIndex: 0,
          zIndex: 4,
          connectors: [
            {
              category: 'Background',
              thumbnail: '/delivery/backgrounds/1.svg',
              thumbnailActive: '/delivery/backgrounds/1.svg',
              variations: ['/delivery/backgrounds/1', '/delivery/backgrounds/2', '/delivery/backgrounds/3', '/delivery/backgrounds/4', '/delivery/backgrounds/5', '/delivery/backgrounds/6', '/delivery/backgrounds/7'],
              activeVariationIndex: 0,
              connectingXProp: 'xbc',
              connectingYProp: 'ybc',
              horizontal: true,
              verticle: true,
              zIndex: 2
            }
          ]
        }
      ]
    },
    {
      name: 'Health Care',
      thumbnail: '/images/charcter2.svg',
      composition: [
        {
          category: 'Head',
          index: 1,
          thumbnail: '/healthcare/head/1.svg',
          thumbnailActive: '/healthcare/head/1.svg',
          variations: ['/healthcare/head/1', '/healthcare/head/2', '/healthcare/head/3', '/healthcare/head/4', '/healthcare/head/5', '/healthcare/head/6', '/healthcare/head/7', '/healthcare/head/8', '/healthcare/head/9'],
          color: [['skin', "Skin"], ['hair', 'Hair']],
          activeVariationIndex: 0,
          zIndex: 4,
          connectors: [
            {
              category: 'Expressions',
              thumbnail: '/healthcare/expressions/1.svg',
              thumbnailActive: '/healthcare/expressions/1.svg',
              variations: ['/healthcare/expressions/1', '/healthcare/expressions/2', '/healthcare/expressions/3', '/healthcare/expressions/4', '/healthcare/expressions/5', '/healthcare/expressions/6', '/healthcare/expressions/7', '/healthcare/expressions/8', '/healthcare/expressions/9'],
              connectingXProp: 'xe',
              connectingYProp: 'ye',
              activeVariationIndex: 0,
              zIndex: 5
            },
            {
              category: 'Accessories',
              thumbnail: '/healthcare/accessories/1.svg',
              thumbnailActive: '/healthcare/accessories/1.svg',
              variations: ['/healthcare/accessories/1', '/healthcare/accessories/2'],
              connectingXProp: 'xa',
              connectingYProp: 'ya',
              activeVariationIndex: 0,
              zIndex: 5
            }
          ]
        },
        {
          category: 'Body',
          index: 0,
          thumbnail: '/healthcare/body/1.svg',
          thumbnailActive: '/healthcare/body/1.svg',
          variations: ['/healthcare/body/1', '/healthcare/body/2', '/healthcare/body/3', '/healthcare/body/4', '/healthcare/body/5', '/healthcare/body/6', '/healthcare/body/7', '/healthcare/body/8', '/healthcare/body/9', '/healthcare/body/10'],
          activeVariationIndex: 0,
          color: [['scrubtop', "Scrub Top"], ['scrubbottom', "Scrub Bottom"], ['apron', "Apron"], ['tshirt', "T-Shirt"], ['shoes', 'Shoes'], ['gown', 'Gown']],
          zIndex: 3,
          connectors: [
            {
              category: 'Background',
              thumbnail: '/healthcare/backgrounds/1.svg',
              thumbnailActive: '/healthcare/backgrounds/1.svg',
              variations: ['/healthcare/backgrounds/1', '/healthcare/backgrounds/2', '/healthcare/backgrounds/3', '/healthcare/backgrounds/4'],
              activeVariationIndex: 0,
              connectingXProp: 'xbc',
              connectingYProp: 'ybc',
              horizontal: true,
              verticle: true,
              zIndex: 1
            }
          ]
        }
      ]
    },
    {
      name: 'Health Care - Female',
      thumbnail: '/images/charcter2.svg',
      composition: [
        {
          category: 'Head',
          index: 1,
          thumbnail: '/healthcare_female/head/1.svg',
          thumbnailActive: '/healthcare_female/head/1.svg',
          variations: ['/healthcare_female/head/1', '/healthcare_female/head/2', '/healthcare_female/head/3', '/healthcare_female/head/4', '/healthcare_female/head/5', '/healthcare_female/head/6', '/healthcare_female/head/7', '/healthcare_female/head/8', '/healthcare_female/head/9'],
          color: [['skin', "Skin"], ['hair', 'Hair']],
          activeVariationIndex: 0,
          zIndex: 4,
          connectors: [
            {
              category: 'Expressions',
              thumbnail: '/healthcare_female/expressions/1.svg',
              thumbnailActive: '/healthcare_female/expressions/1.svg',
              variations: ['/healthcare_female/expressions/1', '/healthcare_female/expressions/2', '/healthcare_female/expressions/3', '/healthcare_female/expressions/4', '/healthcare_female/expressions/5', '/healthcare_female/expressions/6', '/healthcare_female/expressions/7', '/healthcare_female/expressions/8'],
              connectingXProp: 'xe',
              connectingYProp: 'ye',
              activeVariationIndex: 0,
              zIndex: 5
            },
            {
              category: 'Accessories',
              thumbnail: '/healthcare_female/accessories/1.svg',
              thumbnailActive: '/healthcare_female/accessories/1.svg',
              variations: ['/healthcare_female/accessories/1', '/healthcare_female/accessories/2', '/healthcare_female/accessories/3'],
              color: [['mask', "Mask"]],
              connectingXProp: 'xa',
              connectingYProp: 'ya',
              activeVariationIndex: 0,
              zIndex: 5
            }
          ]
        },
        {
          category: 'Body',
          index: 0,
          thumbnail: '/healthcare_female/body/1.svg',
          thumbnailActive: '/healthcare_female/body/1.svg',
          variations: ['/healthcare_female/body/1', '/healthcare_female/body/2', '/healthcare_female/body/3', '/healthcare_female/body/4', '/healthcare_female/body/5', '/healthcare_female/body/6', '/healthcare_female/body/7'],
          activeVariationIndex: 0,
          color: [['scrubtop', "Scrub Top"], ['scrubbottom', "Scrub Bottom"], ['apron', "Apron"], ['tshirt', "T-Shirt"], ['shoes', 'Shoes'], ['gown', 'Gown'], ['dress', 'Dress']],
          zIndex: 5,
          connectors: [
            {
              category: 'Background',
              thumbnail: '/healthcare_female/backgrounds/1.svg',
              thumbnailActive: '/healthcare_female/backgrounds/1.svg',
              variations: ['/healthcare_female/backgrounds/1', '/healthcare_female/backgrounds/2', '/healthcare_female/backgrounds/3', '/healthcare_female/backgrounds/4'],
              activeVariationIndex: 0,
              connectingXProp: 'xbc',
              connectingYProp: 'ybc',
              horizontal: true,
              verticle: true,
              zIndex: 1
            }
          ]
        }
      ]
    }
  ]
