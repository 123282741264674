import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import mergeImages from 'merge-images';
import { parse } from 'svg-parser';
import _ from 'lodash';
import { Slider } from './Slider';
import { setCharacterReducer } from '../../slices/character';
import { svgRemoveWhitespace } from '../../utilities';

export const Canvas = ({currentSelection = null, updatePart = null, updateUnorderPart = null}) => {
    const {characterParts, unOrderedParts, activeChar, colors} = useSelector(state => state.character);
    let unOrderedList = useRef({});
    const dispatch = useDispatch();
    const [character, setCharacter] = useState(null);
    const animationPrefix = '__bounceIn';

    const handleAnimationEnd = (e) => {
        e.stopPropagation();
        const node = document.querySelector('#character-wrapper').querySelector('.active');
        node.classList.remove(`animate__animated`, `animate${animationPrefix}`);
        node.classList.remove('active')
    }

    const updateColor = (character, removeActiveClass = false) => {
      if(!character)
        return '<div>Loading ...</div>';

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(character, "text/xml");

      // Don't show change effect when color is changed
      if(removeActiveClass)
        xmlDoc.getElementsByClassName('active')?.[0]?.classList.remove('active');

      Object.keys(colors).map(colorClass => {
          let elements = xmlDoc.getElementsByClassName(colorClass);
          for(let element of elements){
            element.setAttribute('fill', colors[colorClass]);
          };
      });

      return new XMLSerializer().serializeToString(xmlDoc.documentElement);
    }

    useEffect(() => {
      setCharacter(updateColor(character, true));
    }, [colors])

    useEffect(() => {
        parseUnOrderedParts();
    }, [activeChar])
    
    useEffect(() => {
        setTimeout(() => createCharacter(), 100)
    }, [characterParts, unOrderedParts])
    
    useEffect(() => {
        const node = document.querySelector('#character-wrapper')?.querySelector('g.active');
        
        if(node && node !== null){
          // Animation   
          node.classList.add(`animate__animated`, `animate${animationPrefix}`);
          node.addEventListener('animationend', handleAnimationEnd, {once: true});
        }
        
        if(updatePart === null){
          const c = updateColor(character);
          setCharacter(c);
          dispatch(setCharacterReducer(c));
        }else
          dispatch(setCharacterReducer(character));
    }, [character])

    const createCharacter = async () => {
        let totalWidth, totalHeight;
        let connectorsHeight = 0;
        let connectorsWidth = 0;
        const parts = await new Promise((resolve, reject) => {
          let parts = [];
    
          characterParts.map(async (part, index) => {
            let src = `${part}.svg`;
            let response = await axios({
              method: 'GET',
              url: src
            });
            
            let connectingPos = _.find(parse(response.data)?.children?.[0]?.children, ["tagName", "metadata"])?.children?.[0]?.properties ?? null;
            let rect = parse(response.data)?.children?.[0].properties ?? null;
    
            if (connectingPos && rect) {
              let c = _.find(activeChar, ["index", index]);
              parts.push({ category: c?.category, zIndex: c?.zIndex ?? 1, index, src, svgData: response.data, connectingPos, rect, width: rect.width, height: rect.height - connectingPos.y - (connectingPos.yb ?? 0) });
              const connectors = unOrderedParts?.[index];
              if(connectors){
                for (let key in connectors) {
                  let connector = connectors[key];
                  if(unOrderedList?.current?.[connector.part]?.verticle === true){
                    connectorsHeight += unOrderedList?.current?.[connector.part].connectingPos.y;
                  }
                  if(unOrderedList?.current?.[connector.part]?.horizontal === true){
                    connectorsWidth += unOrderedList?.current?.[connector.part].connectingPos.x;
                  }
                }
              }
            }
    
            if (parts.length === characterParts.length) {
              resolve(_.sortBy(parts, ["index"]));
            }
          });
        });
    
        if (parts) {
          totalWidth = _.sumBy(parts, 'width') + connectorsWidth;
          totalHeight = _.sumBy(parts, "height") + connectorsHeight;
          
          let heightStack = 0;
          let connectImages = {};
          let connectingPointX;
    
          let images = parts.map((p, index) => {
            heightStack += p.height - (parts[index - 1]?.connectingPos?.yt ?? 0);
            connectingPointX = index === 0 ? (((totalWidth - parts[index + 1].rect.width) / 2) + parts[index + 1].connectingPos.x - p.connectingPos.x) : (connectingPointX + parts[index - 1].connectingPos.x - (p.connectingPos?.xb ?? 0));
    
            // Place Unordered Elements
            const connectors = unOrderedParts?.[index];
            if (connectors) {
              for (let key in connectors) {
                let connector = connectors[key];
                
                if (connector && unOrderedList?.current?.[connector.part] && !isNaN(parts[index]?.connectingPos?.[connector.connector.connectingYProp])) {
                  const src = `${connector.part}.svg`;
                  let connectingPos = unOrderedList?.current?.[connector.part];
                  const x = connectingPointX + (parts[index].connectingPos[connector.connector.connectingXProp] - (connectingPos?.connectingPos?.x ?? 0))
                  connectImages[connector.connector.category] = {category: connector.connector.category, zIndex: connectingPos.zIndex, src, svgData: connectingPos.svgData, x, y: totalHeight - heightStack - (connectingPos?.connectingPos?.y ?? 0) + parts[index].connectingPos[connector.connector.connectingYProp] };
                } else {
                  delete connectImages?.[connector?.connector?.category]
                }
              }
            }
            
            return { category: p.category, zIndex: p.zIndex, src: p.src, svgData: p.svgData, x: connectingPointX, y: totalHeight - heightStack }
          });
    
          let allSections = Object.keys(connectImages).length ? [...images, ...(Object.keys(connectImages).map(key => connectImages[key]))] : images;
          // Sort all the parts
          allSections = _.sortBy(allSections, ["zIndex"])
    
          // Generate final character as SVG
          const svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
          svg.setAttribute('id', 'char-preview');
          svg.setAttribute('width', totalWidth);
          svg.setAttribute('height', totalHeight);
          svg.setAttribute('viewbox', `0 0 ${totalWidth} ${totalHeight}`);
          svg.setAttribute('fill', 'none')
          svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
          console.log('allSections', allSections)
          allSections.map(section => {
            let elem = document.createElement('div');
            elem.innerHTML = section.svgData;
            let svgXML = elem.getElementsByTagName('svg')[0].innerHTML;
            elem.getElementsByTagName('svg')[0].innerHTML = `<g data-category="${section.category}" class="path-group" transform="translate(${section.x}, ${section.y})"></g>`;
            elem.getElementsByTagName('g')[0].innerHTML = currentSelection + '.svg' == section.src ? `<g class="active">${svgXML}</g>` : svgXML;
            svg.appendChild(elem.getElementsByTagName('g')[0]);
          });
          
         // svgRemoveWhitespace(svg);
          let outerHTML = svg.outerHTML;
          setCharacter(updateColor(outerHTML));
        }
    };
    
    const parseUnOrderedParts = async () => {
    
        await new Promise((resolve, reject) => {
          activeChar.map((part, index) => {
            if (!part?.connectors)
              return null;
            part?.connectors.map(connector => {
              connector?.variations.map(async (variation, i) => {
                let src = `${variation}.svg`;
                console.log('unOrderedList-src', src)
                let response = await axios({
                  method: 'GET',
                  url: src
                });
                let connectingPos = _.find(parse(response.data)?.children?.[0]?.children, ["tagName", "metadata"])?.children?.[0]?.properties ?? null;
                let rect = parse(response.data)?.children?.[0].properties ?? null;
      
                if (connectingPos && rect) {
                  unOrderedList.current[variation] = ({ zIndex: connector?.zIndex ?? 1, horizontal: connector?.horizontal ?? false, verticle: connector?.verticle ?? false, index, src, svgData: response.data, connectingPos, rect, width: rect.width, height: rect.height - connectingPos.y - (connectingPos.yb ?? 0) });
                }

                if(activeChar.length - 1 === index && connector.variations.length - 1 === i){
                  resolve('');
                }
              })
            })
          });
        });
    }

  return (
    <>
      <div id={`${updatePart === null ? 'preview-wrapper' : 'character-wrapper'}`}  className="main-img" dangerouslySetInnerHTML={{__html: character}}>
      {/* {character ? <img src={character} alt="" /> : 'No Character'} */}
      </div>
      {updatePart !== null && <Slider character={character} activeChar={activeChar} updatePart={updatePart} updateUnorderPart={updateUnorderPart} />}
    </>
  )
}
