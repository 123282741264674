import React, { useEffect, useState } from 'react'
import CSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from 'lodash';
import { draggable } from '../../utilities';

export const Slider = ({character, activeChar, updateUnorderPart, updatePart}) => {
    const [position, setPosition] = useState({t: 0, l: 0, d: 'none', activeCategory: {}, variations: []});
    const [activeItem, setActiveItem] = useState(null);
    const [expand, setExpand] = useState(false);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        accessibility: true,
        slidesToScroll: 1,
        variableWidth: true,
    };

    useEffect(() => {
        document.addEventListener('click', removeSlider);

        draggable(document.querySelector('#parts-slider'), document.querySelector('#parts-slider'));

        return () => document.removeEventListener('click', removeSlider);
    }, [])

    useEffect(() => {
        const g = document.querySelectorAll('g.path-group');
        if(g !== null){
          // Hover event
          g.forEach(t => {
            t.removeEventListener('click', showCategoryParts);
            t.addEventListener('click', showCategoryParts);
          })
        }
    
    }, [character])

    const removeSlider = (e) => {
        if(!e.target.closest('#parts-slider') && !e.target.closest('.path-group')){
            setPosition(position => ({...position, d: 'none'}))
        }
    }

    function showCategoryParts(e){
        const t = this.getBoundingClientRect().top;
        const l = this.getBoundingClientRect().left;
        const w = this.getBoundingClientRect().width;
        let index = 0;
        let part = _.find(activeChar, ["category", this.dataset.category]);
        if(!part){
            for(; index <= activeChar.length; index++){
                part = _.find(activeChar[index]?.connectors ?? {}, ["category", this.dataset.category]);
                if(part)
                    break;
            }
            
        }

        setPosition({t, l: l + w + 30, d: 'block', sectionIndex: activeChar[index]?.connectors ? activeChar?.[index].index : null, activeCategory: part, variations: part.variations});
    }

    const selectItem = (e, part) => {
        e.preventDefault();
        setActiveItem(part);
        
        position.activeCategory?.connectingXProp ? updateUnorderPart(e, position.sectionIndex, part, position.activeCategory) : updatePart(e, position.activeCategory.index, part)
    }

    return (
        <div className='character-container' id="parts-slider" style={{top: `${position.t}px`, left: `${position.l}px`, display: position.d}}>
            <ul className='character-ul' id="character-ul" style={{display: expand ? 'none' : 'block'}}>
                <CSlider {...settings}>
                {
                    position?.variations
                    &&
                    position?.variations.map((part, index) => {
                        return <li key={part} className={`character-item ${activeItem == part ? 'active' : ''}`}>
                                    <figure className="character-image" onClick={e => selectItem(e, part)}>
                                        <img src={`${part}.svg`} />
                                    </figure>
                                </li>
                    })
                }
                </CSlider>
            </ul>
            {
                position?.variations?.length > 4
                &&
                <>
                    <ul className="character-ul character-wrap" id="characters-ul" style={{display: expand ? 'flex' : 'none'}}>
                        {
                            position?.variations
                            &&
                            position?.variations.map((part, index) => {
                                return (
                                    <li key={part} className={`character-item ${activeItem == part ? 'active' : ''}`}>
                                        <figure className="character-image" onClick={e => selectItem(e, part)}>
                                            <img src={`${part}.svg`} />
                                        </figure>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <button className={`toggle-up-down ${expand ? 'active' : ''}`} onClick={e => setExpand(expand => !expand)}>
                        <img src="/tool/images/down-arrow-white.svg" />
                    </button>
                </>
            }
        </div>
    )
}