export function cropWhiteSpaceArountImage(src, resolve){
    let img = new Image();
    let $canvas = document.createElement('canvas');
    let canvas = $canvas;
    let context;

    let removeBlanks = function (imgWidth, imgHeight) {
      let imageData = context.getImageData(0, 0, imgWidth, imgHeight),
        data = imageData.data,
        getRBG = function (x, y) {
          let offset = imgWidth * y + x;
          return {
            red: data[offset * 4],
            green: data[offset * 4 + 1],
            blue: data[offset * 4 + 2],
            opacity: data[offset * 4 + 3]
          };
        },
        isWhite = function (rgb) {
          // many images contain noise, as the white is not a pure #fff white 
          return rgb.opacity <= 0
          //return rgb.red > 200 && rgb.green > 200 && rgb.blue > 200;
        },
        scanY = function (fromTop) {
          let offset = fromTop ? 1 : -1;

          // loop through each row
          for (let y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {

            // loop through each column
            for (let x = 0; x < imgWidth; x++) {
              let rgb = getRBG(x, y);
              if (!isWhite(rgb)) {
                return y;
              }
            }
          }
          return null; // all image is white
        },
        scanX = function (fromLeft) {
          let offset = fromLeft ? 1 : -1;

          // loop through each column
          for (let x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {

            // loop through each row
            for (let y = 0; y < imgHeight; y++) {
              let rgb = getRBG(x, y);
              if (!isWhite(rgb)) {
                return x;
              }
            }
          }
          return null; // all image is white
        };

      let cropTop = scanY(true),
        cropBottom = scanY(false),
        cropLeft = scanX(true),
        cropRight = scanX(false),
        cropWidth = cropRight - cropLeft,
        cropHeight = cropBottom - cropTop;

      const $canvas = document.createElement('canvas');
      $canvas.width = cropWidth;
      $canvas.height = cropHeight;

      let $croppedCanvas = $canvas;

      // finally crop the guy
      $croppedCanvas.getContext("2d").drawImage(canvas,
        cropLeft, cropTop, cropWidth, cropHeight,
        0, 0, cropWidth, cropHeight);

      resolve($croppedCanvas.toDataURL());
    };

    img.crossOrigin = "anonymous";
    img.onload = function () {
      $canvas.width = this.width;
      $canvas.height = this.height;
      context = canvas.getContext("2d");
      if (context) {
        context.drawImage(this, 0, 0);
        removeBlanks(this.width, this.height);
      }
    };

    // define here an image from your domain
    img.src = src;
}

//round to 2 decimal places
//https://stackoverflow.com/a/11832950
function round(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

//remove svg whitespace
export function svgRemoveWhitespace(box, svg) {
    let viewBox = [
                round(box.x),
                round(box.y),
                round(box.width),
                round(box.height)
            ].join(' ');

    console.log('viewBox', viewBox)
    
    svg.setAttribute('viewBox', viewBox);

    return svg;
}

export function randomNumber(max = 10){
  return Math.floor(Math.random() * max) + 1;
}

export function draggable(draghandler, el) {
  draghandler.addEventListener('mousedown', function(e) {
      e.preventDefault();
      
    const offsetX = e.clientX - parseInt(window.getComputedStyle(el).left);
    const offsetY = e.clientY - parseInt(window.getComputedStyle(el).top);

    function mouseMoveHandler(e) {
          e.preventDefault();
          if(document.querySelector('.sketch-picker')){
            return;
          }
          el.style.top = (e.clientY - offsetY) + 'px';
          el.style.left = (e.clientX - offsetX) + 'px';
    }

    function reset() {
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('mouseup', reset);
    }

    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', reset);
  });
}