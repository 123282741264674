import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  character: null,
  colors: {},
  characterParts: [], 
  unOrderedParts: {},
  activeCharIndex: null,
  activeChar: [],
  previewReloadTimeStamp: null,
  shuffleIndex: null
};

export const characterSlice = createSlice({
  name: 'character',
  initialState,
  reducers: {
    resetReducer: () => initialState,
    setCharacterPartsReducer: (state, action) => {
      state.characterParts = action.payload;
    },
    setUnOrderedPartsReducer: (state, action) => {
      state.unOrderedParts = action.payload;
    },
    setActiveCharReducer: (state, action) => {
      state.activeChar = action.payload;
    },
    setActiveCharIndexReducer: (state, action) => {
      state.activeCharIndex = action.payload;
    },
    setCharacterReducer: (state, action) => {
      state.character = action.payload;
    },
    setColorsReducer: (state, action) => {
      state.colors = {...state.colors, ...action.payload};
    },
    setPreviewReloadReducer: (state, action) => {
      state.characterParts = action.payload.characterParts;
      state.unOrderedParts = action.payload.unOrderedParts;
      state.activeChar = action.payload.activeChar;
      state.activeCharIndex = action.payload.activeCharIndex;
      state.colors = action.payload.colors;
    },
    setShuffleIndexReducer: (state, action) => {
      state.shuffleIndex = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCharacterPartsReducer, setUnOrderedPartsReducer, setActiveCharReducer, setActiveCharIndexReducer, setPreviewReloadReducer, setCharacterReducer, setColorsReducer, setShuffleIndexReducer, resetReducer } = characterSlice.actions

export default characterSlice.reducer