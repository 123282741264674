import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { cropWhiteSpaceArountImage } from '../../utilities';

function ExportDropDown(){
    const dropdown = useRef('');
    const [format, setFormat] = useState(null);
    const [scale, setScale] = useState(3);
    const {character} = useSelector(state => state.character);

    useEffect(() => {
        document.addEventListener('click', toggleDropdown);

        return () => document.removeEventListener('click', toggleDropdown);
    }, []);

    useEffect(() => {
        if(format === null)
            return;

        downloadChar(format);
    }, [format])

    const toggleDropdown = (e) => {
        if(e.target.closest('#export-dropdown')){
            dropdown.current.classList.add('open');
        }else{
            dropdown.current.classList.remove('open');
        }
    }

    const download = function (href, name) {
        const link = document.createElement("a");
        link.download = name;
        link.href = href;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const downloadChar = async (format) => {
        console.log('character', character)
        if (character === null)
          return false;
    
        const blob = new Blob([character], { type: 'image/svg+xml;charset=utf-8' });
        const URL = window.URL || window.webkitURL || window;
        const blobURL = URL.createObjectURL(blob);
        switch(format){
            case 'svg':
                download(blobURL, "character");
                break;

            case 'jpg':
            case 'png':
                const image = new Image();
                image.src = blobURL;
                const canva = await new Promise((resolve, reject) => {
                  image.onload = () => {
                    const { width, height } = image;
                    const canva = document.createElement("canvas");
                    canva.width = width * scale;
                    canva.height = height * scale;
                    const context = canva.getContext("2d");
                    context.drawImage(image, 0, 0, width * scale, height * scale);
                    resolve(canva);
                  };
                });

                const png = canva.toDataURL(); // default png          
                const jpg = canva.toDataURL("image/jpg");
                // Remove white space from the image
                const src = await new Promise((resolve) => {
                  cropWhiteSpaceArountImage(format === "png" ? png : jpg, resolve);
                });

                if (format === "png") {
                  download(src, "character.png");
                }
                if (format === "jpg") {
                  download(src, "character.jpg");
                }
                break;
        }
        
    }

    return (
        <div id="export-dropdown" ref={dropdown} className="btn-group for-download Export-btn model-toggle">
            <button className="btn-download">
                Export
            </button>
            <button type="button" className="btn btnDownload dropdown-toggle">
                <span className="caret" />
            </button>
            <ul className="dropdown-menu" role="menu">
                <li><a href="javascript: void(0);" onClick={e => setFormat('svg')}>Svg</a></li>
                <li><a href="javascript: void(0);" onClick={e => setFormat('jpg')}>Jpg</a></li>
                <li><a href="javascript: void(0);" onClick={e => setFormat('png')}>Png</a></li>
            </ul>
        </div>
    )
}

export default ExportDropDown