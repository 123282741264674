import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color';

const hexToRgb = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

const ColorPicker = ({label, anchorClass = '', labelClass = '', defaultColor = null, onChange = null, colorClass = null}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState({});
    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    useEffect(() => {
        defaultColor !== null && setColor(hexToRgb(defaultColor));
    }, [defaultColor])

    const setColorHandler = (value) => {
        if(value.hex !== defaultColor && onChange !== null){
            onChange(value.hex, colorClass);
        }

        setColor(value.rgb);
    }

    const handleClick = (e) => {
        setDisplayColorPicker(!displayColorPicker)
    };
    
    const handleClose = (e) => {
        setDisplayColorPicker(false)
    };

  return (
    defaultColor === null
    ?
    null
    :
    <>
        <a href="#" className={`tools-link ${anchorClass}`} onClick={handleClick}>
            <span className={`tl-button ${labelClass}`} style={{background: `linear-gradient(135.37deg, rgba(197, 190, 173, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), rgb(${color?.r ?? 255}, ${color?.g ?? 255}, ${color?.b ?? 255})`}} />
            <span className="tl-title"> {label} </span>
        </a>
        { 
            displayColorPicker
            ? 
            <div style={ popover }>
                <div style={ cover } onClick={ handleClose } />
                <SketchPicker disableAlpha={true} color={color} onChange={value => setColorHandler(value, )} />
            </div> 
            : 
            null 
        }
    </>
    
  )
}

export default ColorPicker