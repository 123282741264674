import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setPreviewReloadReducer } from '../../slices/character';
import { Canvas } from './Canvas'

const bc = new BroadcastChannel("canvas");

export default function Preview(){
    const [mount, setMount] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        bc.onmessage = (message) => {
            const {characterParts, unOrderedParts, activeCharIndex, activeChar, colors} = message.data;
            dispatch(setPreviewReloadReducer({characterParts, unOrderedParts, activeCharIndex, activeChar, colors}))
        };
        
    }, [])

    return (
        <div className="main-Wrap">
            <section className="main-sec">
                <div className="main-sec-blk Oodles-character">
                    <div className="right-display-sidebar">
                        <div className="main-img">
                            <div className="create-character">
                                <div className="character-block">
                                    <Canvas />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
