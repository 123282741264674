import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColorPicker from './ColorPicker';
import _ from 'lodash';
import { setColorsReducer } from '../../slices/character';
import { draggable } from '../../utilities';

const ColorSwitcher = () => {
    const {unOrderedParts, activeChar, character} = useSelector(state => state.character);
    const [colorsList, setColorsList] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        draggable(document.querySelector('#color-switcher'), document.querySelector('#color-switcher'));
    }, [])

    useEffect(() => {
        let colors = {};

        activeChar.map(char => {
            if(char?.color?.length > 0){
                char.color.map(c => {
                    colors[c[0]] = c;
                })
            }
            
            if(unOrderedParts?.[char.index]){
                const keys = Object.keys(unOrderedParts?.[char.index]);
                keys.map(k => {
                    if(unOrderedParts[char.index][k].connector?.color?.length > 0){
                        unOrderedParts[char.index][k].connector?.color.map(c => {
                            colors[c[0]] = c;
                        })
                        
                    }
                })
            }
        })

        if(Object.keys(colors).length > 0){
            setColorsList(colors)
        }
    }, [unOrderedParts, activeChar]);

    useEffect(() => {
        let colors = {...colorsList};
        const classes = Object.keys(colors);
        if(character === null || classes.length === 0)
            return;

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(character, "text/xml");
        classes.map(c => {
            let fill = xmlDoc.getElementsByClassName(c)?.[0]?.getAttribute('fill') ?? null;
            colors[c] = [colors[c][0], colors[c][1], fill];
        });

        setColorsList(colors);
    }, [character])

    const changeColor = (color, colorClass) => {
        dispatch(setColorsReducer({[colorClass]: color}));
    }

    return (
        <div className="tools-select" id="color-switcher">
             <ul className="ul-tools">
                {
                    Object.keys(colorsList).length > 0
                    &&
                    Object.keys(colorsList).map(key => {
                        let c = colorsList[key];
                        return (
                            c[2] ? <li key={c[0]} className={`li-tools ${c[0]}-tool`}>
                                <ColorPicker colorClass={key} defaultColor={c[2]} label={c[1]} anchorClass={`cl-${c[0]}`} labelClass={`bg-${c[0]}`} onChange={changeColor} />
                            </li> : null
                        )
                    })
                }
                <li className="li-tools background-tool">
                    <a href="#" className="tools-link cl-background">
                        <span className="tl-button bg-none" />
                        <span className="tl-title"> Background </span>
                    </a>
                </li>
            </ul>                   
        </div>
        
    )
}

export default ColorSwitcher