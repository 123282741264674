import { configureStore, combineReducers } from '@reduxjs/toolkit';
import characterReducer from './slices/character'
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage: storage,
  //  blacklist: [],
};
export const rootReducers = combineReducers({
    character: characterReducer
});
const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer
});