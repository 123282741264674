import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import Home from './components/Home';
// import { Preview } from './components/Preview';
import Home from './components/tool/Index';
import Preview from './components/tool/Preview';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/:charSet?",
    element: <Home />,
  },
  {
    path: "preview",
    element: <Preview />,
  },
]);

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
